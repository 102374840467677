import React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  margin: 0 auto 2em;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  span {
    margin: 0 0.5rem;
  }
`

const Date = styled.p`
  display: inline-block;
  color: #777;
  font-size: 0.9em;
  margin-right: 1em;
`

const ReadingTime = styled.p`
  display: inline-block;
  color: #777;
  font-size: 0.9em;
`

const PostDetails = props => {
  return (
    <Wrapper>
      <Date>📅 {props.date}</Date>
      <Date>
        👤
        {props.author}
      </Date>
      <ReadingTime>{`⏱️${props.timeToRead} min `}</ReadingTime>
    </Wrapper>
  )
}

export default PostDetails

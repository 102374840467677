let contentfulConfig
try {
  contentfulConfig = require('./.contentful')
} catch (e) {
  contentfulConfig = {
    production: {
      spaceId: 'f9fdj90w6r40',
      accessToken: 'QkAqyqZAavgIKQFLd4gIF7qsNaIGHRP0wHXqVBo7T_Y',
    },
  }
} finally {
  const { spaceId, accessToken } = contentfulConfig.production
  if (!spaceId || !accessToken) {
    throw new Error(
      'Contentful space ID and access token need to be provided. ' +
        contentfulConfig.production
    )
  }
}

const {
  NODE_ENV,
  URL: NETLIFY_SITE_URL = 'https://elindustrial.mx',
  DEPLOY_PRIME_URL: NETLIFY_DEPLOY_URL = NETLIFY_SITE_URL,
  CONTEXT: NETLIFY_ENV = NODE_ENV,
} = process.env
const isNetlifyProduction = NETLIFY_ENV === 'production'
const siteUrl = isNetlifyProduction ? NETLIFY_SITE_URL : NETLIFY_DEPLOY_URL

module.exports = {
  siteMetadata: {
    title: 'El Industrial',
    description: 'Noticias de negocios de la zona norte',
    siteUrl: 'https://elindustrial.mx',
    image: '/images/share.jpg',
    menuLinks: [
      {
        name: 'Negocios',
        slug: '/tag/negocios/',
      },
      {
        name: 'Economía',
        slug: '/tag/economia/',
      },
      {
        name: 'Finanzas',
        slug: '/tag/finanzas/',
      },
      {
        name: 'Pymes',
        slug: '/tag/pymes/',
      },
      {
        name: 'Tecnología',
        slug: '/tag/tecnologia/',
      },
      {
        name: 'Negocios Deportivos',
        slug: '/tag/NegociosDeportivos/',
      },
      {
        name: 'Opinión',
        slug: '/tag/opinion/',
      },
      {
        name: 'Nacional',
        slug: '/tag/nacional/',
      },
    ],
    postsPerFirstPage: 7,
    postsPerPage: 6,
    basePath: '/',
  },
  plugins: [
    `gatsby-plugin-emotion`,
    'gatsby-plugin-theme-ui',
    'gatsby-plugin-react-helmet',
    `gatsby-plugin-image`,
    `gatsby-plugin-sharp`,
    `gatsby-transformer-sharp`,
    {
      resolve: `gatsby-plugin-typography`,
      options: {
        pathToConfigModule: `src/utils/typography`,
      },
    },
    {
      resolve: `gatsby-transformer-remark`,
      options: {
        plugins: [
          {
            resolve: `gatsby-remark-prismjs`,
          },
          `gatsby-remark-autolink-headers`,
          {
            resolve: `gatsby-remark-images-contentful`,
            options: {
              maxWidth: 650,
              backgroundColor: 'white',
              linkImagesToOriginal: false,
            },
          },
        ],
      },
    },
    `gatsby-plugin-catch-links`,
    {
      resolve: 'gatsby-source-contentful',
      options:
        process.env.NODE_ENV === 'development'
          ? contentfulConfig.development
          : contentfulConfig.production,
    },
    {
      resolve: 'gatsby-plugin-google-analytics',
      options: {
        trackingId: 'UA-196061656-1',
        head: true,
      },
    },
    'gatsby-plugin-sitemap',
    {
      resolve: 'gatsby-plugin-robots-txt',
      options: {
        resolveEnv: () => NETLIFY_ENV,
        env: {
          production: {
            host: 'https://elindustrial.mx',
            sitemap: 'https://elindustrial.mx/sitemap.xml',
            policy: [{ userAgent: '*', allow: '/' }],
          },
          'branch-deploy': {
            policy: [{ userAgent: '*', disallow: ['/'] }],
            sitemap: null,
            host: null,
          },
          'deploy-preview': {
            policy: [{ userAgent: '*', disallow: ['/'] }],
            sitemap: null,
            host: null,
          },
        },
      },
    },
    {
      resolve: 'gatsby-plugin-manifest',
      options: {
        name: 'GCN',
        short_name: 'GCN',
        start_url: '/',
        background_color: '#ffffff',
        theme_color: '#ffffff',
        display: 'minimal-ui',
        icon: './static/images/favicon.png',
      },
    },
    {
      resolve: `gatsby-plugin-disqus`,
      options: {
        shortname: `elindustrial`,
      },
    },
    {
      resolve: `gatsby-plugin-schema-snapshot`,
      options: {
        path: `./src/gatsby/schema/schema.gql`,
        update: process.env.GATSBY_UPDATE_SCHEMA_SNAPSHOT,
      },
    },
    'gatsby-plugin-netlify',
  ],
}

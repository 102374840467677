import React from 'react'
import styled from '@emotion/styled'
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share'
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  PinterestIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share'

const Wrapper = styled.div`
  margin: 0 auto 2em;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  span {
    margin: 0 0.5rem;
  }
`
const iconSize = 30

const ShareButtons = ({ title, url, twitterHandle, tags }) => {
  return (
    <Wrapper>
      <WhatsappShareButton url={url} title={title}>
        <WhatsappIcon size={iconSize} round={true} />
      </WhatsappShareButton>{' '}
      <FacebookShareButton url={url}>
        <FacebookIcon size={iconSize} round={true} />
      </FacebookShareButton>{' '}
      <TwitterShareButton url={url} title={title} via={twitterHandle}>
        <TwitterIcon size={iconSize} round={true} />
      </TwitterShareButton>{' '}
      <LinkedinShareButton url={url}>
        <LinkedinIcon size={iconSize} round={true} />
      </LinkedinShareButton>{' '}
      <TelegramShareButton url={url}>
        <TelegramIcon size={iconSize} round={true} />
      </TelegramShareButton>{' '}
      <EmailShareButton
        subject={title}
        url={url}
        body={'Este artículo puede interesarte. '}
      >
        <EmailIcon size={iconSize} round={true} />
      </EmailShareButton>
    </Wrapper>
  )
}
export default ShareButtons
